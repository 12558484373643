<template>
  <div v-if="companyName" ref="companyProjectForm" class="container">

    <div class="centered mb-lg-5">
      <h3>{{ companyName }} Projects</h3>
    </div>

    <el-carousel v-if="projects.length > 0" :interval="2500" :type="getType" height="350px">
      <el-carousel-item v-for="project in projects" :key="project.id">
        <div class="item">
          <div class="item__content">
            <p class="item__content__title">{{ project.title }}</p>
            <div v-if="project.logo" class="item__content__img-container">
              <img :src="getUrl(project.logo)" class="logo">
            </div>
            <p v-if="project.description.length <= 150" class="item__content__description">{{ project.description }}</p>
            <p v-else class="item__content__description">{{ project.description.substring(0, 150) + ".." }}</p>
            <div class="item__content__links">
              <router-link :to="'/projects/'+project.uuid+'/preview'">View</router-link>
              <a :href="project.proof_link" target="_blank">Proof Link</a>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="centered" v-else>
      <h4>There are no projects related to this company.</h4>
    </div>

  </div>
</template>

<script>
import {Carousel, CarouselItem} from 'element-ui'

export default {

  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },

  data() {
    return {
      loader: {},
      projects: [],
      companyName: "",
      windowWidth: window.innerWidth
    }
  },
  created() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    })
    this.getProjects();
  },

  computed: {
    getType() {
      if (this.windowWidth < 1350) {
        return null;
      } else {
        return 'card';
      }
    }
  },
  methods: {
    async getProjects() {
      try {
        this.loader = this.$loading.show({
          container: this.$refs.companyProjectForm
        });

        let uuid = this.$route.params['uuid'];

        let res = await this.axios.get("companies/" + uuid + "/projects");

        this.projects = res.data.projects;
        this.companyName = res.data.company.name;

      } catch (e) {
        if (e.response !== undefined && e.response.status === 404) {
          this.$notify({
            message: "Company Not Found",
            timeout: 2000,
            type: 'danger'
          });
        }
      } finally {
        this.loader.hide();
      }
    },

    getUrl(path) {
      return process.env.VUE_APP_SERVER_IMAGE_URL + path;
    }

  }

}

</script>
<style scoped lang="scss">
.centered {
  display: flex;
  justify-content: center;
}

.item {
  position: relative;
  height: 100%;
}

.item__content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 1rem 1rem 2rem;
}

.item__content__title {
  font-size: 25px;
}


.item__content__img-container .logo {
  width: 160px;
  height: 130px;
  border-radius: 3%;
}

.item__content__description {
  margin-top: 10px;
  text-align: center;
  width: 80%;
  overflow-wrap: break-word;
}

.item__content__links {
  margin-top: auto;
  margin-bottom: 10px;
}

.item__content__links a {
  font-size: 16px;
  color: white;
  margin: 0 10px;
  padding: 3px;
}

.item__content__links a {
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid  rgba(255,255,255,0.5);
  border-radius: 5%;
  z-index: 1000;
}

.item__content__links a:hover {
  color: rgb(134, 131, 131);
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(0, 0, 0, 0.5);
}


.el-carousel__item:nth-child(2n) {
  background-color: #d0c7c9;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d0c7c9;
}

</style>
